import {PropertyLimit} from '@/modules/report/limit/PropertyLimit';
import {ReportChart} from '@/models/report';

export class ColumnChartLimit implements PropertyLimit {
    private chart: ReportChart;

    constructor(chart: ReportChart) {
        this.chart = chart;
    }

    public getDimensionMaxLimit(): number {
        let limit = 3;
        if (this.chart.metrics.length > 1) {
            return 1;
        }
        if (this.chart.useSegments) {
            limit--;
        }
        if (this.chart.useDateContext) {
            limit--;
        }
        return limit;
    }

    public getMetricMaxLimit(): number {
        if (this.chart.dimensions.length > 1) {
            return 1;
        }
        return 5;
    }

    public getDimensionMinLimit(): number {
        return 1;
    }

    public getMetricMinLimit(): number {
        return 1;
    }
}

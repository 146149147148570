
import {GChart} from 'vue-google-charts/legacy';
import {Component, Prop, Vue, Ref, Watch} from 'vue-property-decorator';
import {colors} from '@/config/common';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {GChartBubbleSeriesBuilder} from '@/util/GChartBubbleSeriesBuilder';
import {
  DateContext,
  DateContextEvent,
  DateContextPeriod,
  DateContextTransaction,
} from '@/models/date-context';

@Component({
  components: {
    GChart,
    // BaseChart,
  }
})
export default class BubbleChart extends Vue {
  @Prop() private data!: AnalyticsApiQueryResponse;
  @Prop() private options!: any;
  @Prop() private dimensions!: string[];
  @Prop() private metrics!: string[];
  @Prop() private dateContext!: DateContext | DateContextTransaction | DateContextEvent | DateContextPeriod | undefined;

  get chartData(): any[] {
    console.log(new GChartBubbleSeriesBuilder(this.data)
        .setDateContext(this.dateContext)
        .setDimensions(this.dimensions)
        .setMetrics(this.metrics)
        .build());

    return new GChartBubbleSeriesBuilder(this.data)
        // .setDateRangeDimension('te.dateRange')
        .setDateContext(this.dateContext)
        .setDimensions(this.dimensions)
        .setMetrics(this.metrics)
        .build();


    // return [
    //   ['ID', 'Life Expectancy', 'Fertility Rate', 'Region', 'Population'],
    //   ['CAN', 80.66, 1.67, 'North America', 33739900],
    //   ['DEU', 79.84, 1.36, 'Europe', 81902307],
    //   ['DNK', 78.6, 1.84, 'Europe', 5523095],
    //   ['EGY', 72.73, 2.78, 'Middle East', 79716203],
    //   ['GBR', 80.05, 2, 'Europe', 61801570],
    //   ['IRN', 72.49, 1.7, 'Middle East', 73137148],
    //   ['IRQ', 68.09, 4.77, 'Middle East', 31090763],
    //   ['ISR', 81.55, 2.96, 'Middle East', 7485600],
    //   ['RUS', 68.6, 1.54, 'Europe', 141850000],
    //   ['USA', 78.09, 2.05, 'North America', 307007000],
    // ]
  }

  get chartOptions(): any {
    const defaultOptions = {
      // legend: {position: 'top'},
      // width: 800,
      height: 500,
      colors: colors,
      fontName: 'heebo',
      fontSize: 14,
      // chartArea:{width:'92%'},
      hAxis: {
        title: this.hasMetrics ? this.$t('metricsAndDimensions.name.'+this.metrics[0]) : '',
        // gridlines: {
        //   color: '#D0DCE2',
        // },
        // minorGridlines: {
        //   count: 0
        // },
      },
      vAxis: {
        title: this.hasMetrics ? this.$t('metricsAndDimensions.name.'+this.metrics[1]) : '',
        // minorGridlines: {
        //   count: 0
        // },
      },
    };
    return Object.assign(defaultOptions, this.options);

    // return {
    //   title:
    //       'Correlation between life expectancy, fertility rate ' +
    //       'and population of some world countries (2010)',
    //   hAxis: { title: 'Life Expectancy' },
    //   vAxis: { title: 'Fertility Rate' },
    //   bubble: { textStyle: { fontSize: 11 } },
    //   // width: 800,
    //   height: 600,
    // }
  }

  get hasMessage(): boolean {
    return !this.hasData || !this.hasDimensions || !this.hasMetrics;
  }

  get hasData(): boolean {
    return this.data && this.data.data.rows.length > 0;
  }

  get hasMetrics(): boolean {
    return this.metrics && this.metrics.length > 0;
  }

  get hasDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 0;
  }
}

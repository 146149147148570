
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BaseLayout from '@/views/BaseLayout.vue';
import {Report} from '@/models/report';
import loading from '@/util/Loading';
import BasicTable from '@/components/BasicTable.vue';
import {TableColumn} from '@/models/table';
import Button from '@/components/Button.vue';

const namespace: string = 'report';

@Component({
  components: {
    BaseLayout,
    BasicTable,
    'te-button': Button,
  }
})
export default class ReportListView extends Vue {
  @Getter('inEditMode') inEditMode!: boolean;
  @Getter('reports') reports?: Report;
  @Action('fetchReports') fetchReports: any;


  get isLoading(): boolean {
    return loading.isLoadingJobType(['fetch-report']);
  }

  get columns(): TableColumn[] {
    return [
      {key: 'title', label: this.$t('common.name'), link: {routeName: 'report-detail', params: [{paramKey: 'id', valueKey: 'id'}]}},
    ];
  }

  public createNewReport(): void {
    this.$router.push({name:'report-detail', params: {id: 'new'}});
  }

  public async fetchData(): Promise<void> {
    await this.fetchReports();
  }

  async mounted() {
    await this.fetchData();
  }

}


import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {PropertyOption} from '@/models/report';
import PropertyPanelSelect from '@/modules/report/components/PropertyPanelSelect.vue';
import Icon from '@/components/Icon.vue';
import IconButton from '@/components/IconButton.vue';


@Component({
  components: {
    IconButton,
    PropertyPanelSelect,
    Icon
  }
})
export default class DimensionMetricEditor extends Vue {
  @Prop({required: true}) private options?: PropertyOption[];
  @Prop({required: true}) private value?: string[];
  @Prop({default: 5}) private max?: number;
  @Prop({default: 1}) private min?: number;
  private localState: { selected: string[] } = {selected: []};


  get canAdd(): boolean {
    return !(this.max && this.localState.selected.length >= this.max);
  }

  get canRemove(): boolean {
    const min = this.min ?? 1;
    return this.localState.selected.length > min;
  }

  public getOptions(value: string): PropertyOption[] {
    if (this.options && this.options.length > 0) {
      return this.options.filter((o) => !this.localState.selected.includes(o.id) || o.id === value);
    }
    return [];
  }

  public onAdd(): void {
    if (this.options && this.options.length > 0) {
      this.localState.selected.push(this.getOptions('')[0].id);
    }
  }

  @Emit('input')
  public onRemove(value: string): string[] {
    this.localState.selected = this.localState.selected.filter((v) => v !== value);
    return this.localState.selected;
  }

  @Emit('input')
  public onSelect(change: { newValue: string, oldValue: string }): string[] {
    this.localState.selected = this.localState.selected.map((value) => {
      if (value === change.oldValue) {
        return change.newValue;
      }
      return value;
    });
    return this.localState.selected;
  }

  @Watch('value')
  onValueChanged(value: string[]) {
    this.localState.selected = value;
  }

  async created() {
    if (this.value) {
      this.localState.selected = this.value;
    }
  }
}


import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import {PropertyOption, ReportChart, ReportChartType, TE_DATASOURCE_NAME} from '@/models/report';
import Button from '@/components/Button.vue';
import SelectBox from '@/components/Input/SelectBox.vue';
import {DataSourceSchemaFactory} from '@/modules/report/DataSourceSchemaFactory';
import DimensionMetricEditor from '@/modules/report/components/DimensionMetricEditor.vue';
import PropertyPanelSelect from '@/modules/report/components/PropertyPanelSelect.vue';
import {PropertyLimit} from '@/modules/report/limit/PropertyLimit';
import {PropertyLimitFactory} from '@/modules/report/limit/PropertyLimitFactory';

@Component({
  components: {
    'te-button': Button,
    SelectBox,
    DimensionMetricEditor,
    PropertyPanelSelect,
  }
})
export default class ChartPropertyEditor extends Vue {
  @Prop({required:true}) private value?: ReportChart;
  private localState: {chart: ReportChart|null} = {chart: null};

  get chartType(): string {
    if(this.localState.chart) {
      return this.localState.chart.type;
    }
    return '';
  }

  get dataSourceOptions(): PropertyOption[] {
    return [
      // {id: 'TransactionLines', label: this.$t('report.dataSource.type.TransactionLines.name'), description: 'report.dataSource.type.TransactionLines.description'},
      {id: TE_DATASOURCE_NAME.orderLines, label: this.$t('report.dataSource.type.OrderLines.name'), description: 'report.dataSource.type.OrderLines.description'},
      {id: TE_DATASOURCE_NAME.payments, label: this.$t('report.dataSource.type.Payments.name'), description: 'report.dataSource.type.Payments.description'},
    ]
  }

  get dimensionOptions(): PropertyOption[] {
    const options: PropertyOption[] = [];
    if(this.localState.chart && this.localState.chart.dataSource) {
      DataSourceSchemaFactory.create(this.localState.chart.dataSource).dimensions.forEach((dimension) => {
        options.push({id: dimension.name, label: this.$t('metricsAndDimensions.name.'+dimension.name), description: this.$t('metricsAndDimensions.description.'+dimension.name)});
      });
    }
    return options;
  }

  get metricOptions(): PropertyOption[] {
    const options: PropertyOption[] = [];
    if(this.localState.chart && this.localState.chart.dataSource) {
      DataSourceSchemaFactory.create(this.localState.chart.dataSource).metrics.forEach((metric) => {
        options.push({id: metric.name, label: this.$t('aggregationFunctions.'+metric.aggregateFunction)+': '+this.$t('metricsAndDimensions.name.'+metric.name), description: this.$t('metricsAndDimensions.description.'+metric.name)});
      });
    }
    return options;
  }

  get maxDimensions(): number {
    if(this.localState.chart) {
      const limit = PropertyLimitFactory.create(this.localState.chart);
      return limit.getDimensionMaxLimit();
    }
    return 1;
  }

  get minDimensions(): number {
    if(this.localState.chart) {
      const limit = PropertyLimitFactory.create(this.localState.chart);
      return limit.getDimensionMinLimit();
    }
    return 1;
  }

  get maxMetrics(): number {
    if(this.localState.chart) {
      const limit = PropertyLimitFactory.create(this.localState.chart);
      return limit.getMetricMaxLimit();
    }
    return 1;
  }

  get minMetrics(): number {
    if(this.localState.chart) {
      const limit = PropertyLimitFactory.create(this.localState.chart);
      return limit.getMetricMinLimit();
    }
    return 1;
  }

  get canUseDimensions(): boolean {
    return this.maxDimensions > 0;
  }

  get canUseMetrics(): boolean {
    return this.maxMetrics > 0;
  }

  get canUseSegments(): boolean {
    return this.localState.chart ? [ReportChartType.line, ReportChartType.bar, ReportChartType.table].includes(this.localState.chart.type) : false;
  }

  get canUseDateContext(): boolean {
    return this.localState.chart ? [ReportChartType.line, ReportChartType.bar, ReportChartType.table].includes(this.localState.chart.type) : false;
  }

  get canUseReportFilters(): boolean {
    return true;
  }

  get canIncludeEmptyRows(): boolean {
    return this.localState.chart ? [ReportChartType.line, ReportChartType.table].includes(this.localState.chart.type) : false;
  }

  public onSelectDataSourceName(change: {newValue: string, oldValue: string}): void {
    if(this.localState.chart) {
      this.localState.chart.dataSource.name = change.newValue;
    }
  }


  @Emit("change")
  public preview(): ReportChart|null {
    // return this.localState.chart;
    return JSON.parse(JSON.stringify(this.localState.chart))
  }

  @Emit("close")
  public close(): ReportChart|null {
    return this.localState.chart;
  }

  @Emit("remove")
  public remove(): ReportChart|null {
    return this.localState.chart;
  }

  @Watch('value')
  onValueChanged(chart: ReportChart) {
    // this.localState.chart = Object.assign({}, chart);
    this.localState.chart = JSON.parse(JSON.stringify(chart));
  }

  async created() {
    if(this.value) {
      // this.localState.chart = Object.assign({}, this.value);
      this.localState.chart = JSON.parse(JSON.stringify(this.value));
    }
  }

  /**
   * valideren input
   *    aantal metrics en dimensions
   *
   */


}


import {GChart} from 'vue-google-charts/legacy';
import {Component, Prop, Vue, Ref, Watch} from 'vue-property-decorator';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {DateContext, DateContextEvent, DateContextPeriod, DateContextTransaction} from '@/models/date-context';
import {GChartSeriesBuilder} from '@/util/GChartSeriesBuilder';
import {colors} from '@/config/common';

@Component({
  components: {
    GChart,
  }
})
export default class ColumnChart extends Vue {
  @Prop() private data!: AnalyticsApiQueryResponse;
  @Prop() private options!: any;
  @Prop() private dimensions!: string[];
  @Prop() private metrics!: string[];
  @Prop() private dateContext!: DateContext | DateContextTransaction | DateContextEvent | DateContextPeriod | undefined;

  get chartData(): any[] {
    return new GChartSeriesBuilder(this.data)
        // .setDateRangeDimension('te.dateRange')
        .setDateContext(this.dateContext)
        .setDimensions(this.dimensions)
        .setMetrics(this.metrics)
        .build();
  }

  get chartOptions(): any {
    const defaultOptions = {
      legend: {position: 'top'},
      height: 500,
      colors: colors,
      fontName: 'heebo',
      fontSize: 14,
      chartArea: {width: '90%'},
      // focusTarget: 'category',
      hAxis: {
        title: this.hasDimensions ? this.$t('metricsAndDimensions.name.'+this.dimensions[0]) : '',
        gridlines: {
          color: '#D0DCE2',
        },
        minorGridlines: {
          count: 0
        },
      },
      vAxis: {
        minorGridlines: {
          count: 0
        },
      },
    };
    return Object.assign(defaultOptions, this.options);
  }

  get hasMessage(): boolean {
    return !this.hasData || !this.hasDimensions || !this.hasMetrics;
  }

  get hasData(): boolean {
    return this.data && this.data.data.rows.length > 0;
  }

  get hasMetrics(): boolean {
    return this.metrics && this.metrics.length > 0;
  }

  get hasDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 0;
  }

}

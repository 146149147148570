
import {GChart} from 'vue-google-charts/legacy';
import {Component, Prop, Vue, Ref, Watch} from 'vue-property-decorator';
import {colors} from '@/config/common';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {GChartSeriesBuilder} from '@/util/GChartSeriesBuilder';
import {
  DateContext,
  DateContextEvent,
  DateContextPeriod,
  DateContextTransaction,
} from '@/models/date-context';

@Component({
  components: {
    GChart,
    // BaseChart,
  }
})
export default class Treemap extends Vue {
  @Prop() private data!: AnalyticsApiQueryResponse;
  @Prop() private options!: any;
  @Prop() private dimensions!: string[];
  @Prop() private metrics!: string[];
  @Prop() private dateContext!: DateContext | DateContextTransaction | DateContextEvent | DateContextPeriod | undefined;

  get chartData(): any[] {
console.log(this.data);
console.log(new GChartSeriesBuilder(this.data)
    // .setDateRangeDimension('te.dateRange')
    .setDateContext(this.dateContext)
    .setDimensions(this.dimensions)
    .setMetrics(this.metrics)
    .build());

    return new GChartSeriesBuilder(this.data)
        // .setDateRangeDimension('te.dateRange')
        .setDateContext(this.dateContext)
        .setDimensions(this.dimensions)
        .setMetrics(this.metrics)
        .build();
    // return [
    //   [
    //     'Location',
    //     'Parent',
    //     'Market trade volume (size)',
    //     'Market increase/decrease (color)',
    //   ],
    //   ['Global', null, 0, 0],
    //   ['America', 'Global', 0, 0],
    //   ['Europe', 'Global', 0, 0],
    //   ['Asia', 'Global', 0, 0],
    //   ['Australia', 'Global', 0, 0],
    //   ['Africa', 'Global', 0, 0],
    //   ['Brazil', 'America', 11, 10],
    //   ['USA', 'America', 52, 31],
    //   ['Mexico', 'America', 24, 12],
    //   ['Canada', 'America', 16, -23],
    //   ['France', 'Europe', 42, -11],
    //   ['Germany', 'Europe', 31, -2],
    //   ['Sweden', 'Europe', 22, -13],
    //   ['Italy', 'Europe', 17, 4],
    //   ['UK', 'Europe', 21, -5],
    //   ['China', 'Asia', 36, 4],
    //   ['Japan', 'Asia', 20, -12],
    //   ['India', 'Asia', 40, 63],
    //   ['Laos', 'Asia', 4, 34],
    //   ['Mongolia', 'Asia', 1, -5],
    //   ['Israel', 'Asia', 12, 24],
    //   ['Iran', 'Asia', 18, 13],
    //   ['Pakistan', 'Asia', 11, -52],
    //   ['Egypt', 'Africa', 21, 0],
    //   ['S. Africa', 'Africa', 30, 43],
    //   ['Sudan', 'Africa', 12, 2],
    //   ['Congo', 'Africa', 10, 12],
    //   ['Zaire', 'Africa', 8, 10],
    // ];
  }

  get chartOptions(): any {
    // const defaultOptions = {
      // title: 'Company Performance',
      // curveType: 'function',
      // legend: {position: 'top'},
      // // legend: { position: 'bottom' },
      // // width: 800,
      // height: 500,
      // // colors: ['#FFAE00', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'],
      // colors: colors,
      // fontName: 'heebo',
      // fontSize: 14,
      // // selectionMode: 'multiple',
      // // tooltip: {trigger: 'selection'},
      // // aggregationTarget: 'category',
      // chartArea:{width:'92%'},
      // focusTarget: 'category',
      // hAxis: {
      //   title: this.hasDimensions ? this.$t('metricsAndDimensions.name.'+this.dimensions[0]) : '',
      //   // title: 'Time'
      //   // format: 'short',
      //   gridlines: {
      //     color: '#D0DCE2',
      //     // minSpacing: 20
      //   },
      //   minorGridlines: {
      //     count: 0
      //   },
      // },
      // vAxis: {
      //   // title: 'Popularity'
      //   minorGridlines: {
      //     count: 0
      //   },
      // },
    // };
    // return Object.assign(defaultOptions, this.options);
return {
  minColor: '#f00',
  midColor: '#ddd',
  maxColor: '#0d0',
  headerHeight: 15,
  fontColor: 'black',
  showScale: true,
  // width: 600,
  height: 500,
}

  }

  get settings(): object {
    return {
      packages: ['treemap'],
    };
  }

  get hasMessage(): boolean {
    return !this.hasData || !this.hasDimensions || !this.hasMetrics;
  }

  get hasData(): boolean {
    return this.data && this.data.data.rows.length > 0;
  }

  get hasMetrics(): boolean {
    return this.metrics && this.metrics.length > 0;
  }

  get hasDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 0;
  }
}


import {Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator';
import {Filter as FilterOption} from '@/models/analytics-api';
import Icon from '@/components/Icon.vue';
import IconButton from '@/components/IconButton.vue';
import Button from "@/components/Button.vue";
import PropertyPanelSelect from '@/modules/report/components/PropertyPanelSelect.vue';
import {DataSourceType, PropertyOption, ReportFilter, TE_DATASOURCE_NAME} from '@/models/report';
import {DataSourceSchemaFactory} from '@/modules/report/DataSourceSchemaFactory';


@Component({
  components: {
    Icon,
    IconButton,
    'te-button': Button,
  }
})
export default class FilterContext extends Vue {
  @Prop({required:true}) private value?: ReportFilter[];
  // private localState: {filters: ReportFilter[]} = {filters: []};
  private localState: {filters: ReportFilter[]} = {filters: [{filters: [], dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: TE_DATASOURCE_NAME.orderLines}}]};
  private openFilterManagement: boolean = false;

  // get primaryFilter(): ReportFilter {
  //   if(this.localState.filters.length > 0) {
  //     return this.localState.filters[0];
  //   }
  //   return {filters: [], dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: 'TransactionLines'}};
  // }
  get primaryFilter(): FilterOption[] {
    if(this.localState.filters.length > 0) {
      return this.localState.filters[0].filters;
    }
    return [];
  }

  get hasFilters(): boolean {
    return this.localState.filters.length > 0 && this.localState.filters[0].filters.length > 0;
  }

  public toggleManagement(): void {
    this.openFilterManagement = !this.openFilterManagement;
  }

  public getKeyOptions(key: string): PropertyOption[] {
    const options: PropertyOption[] = [];
    if(this.localState.filters.length > 0) {
      const schema = DataSourceSchemaFactory.create(this.localState.filters[0].dataSource);
      schema.dimensions.forEach((dimension) => {
        options.push({id: dimension.name, label: this.$t('metricsAndDimensions.name.'+dimension.name), description: this.$t('metricsAndDimensions.description.'+dimension.name)});
      });
      schema.metrics.forEach((metric) => {
        options.push({id: metric.name, label: this.$t('metricsAndDimensions.name.'+metric.name), description: this.$t('metricsAndDimensions.description.'+metric.name)});
      });
      return options.filter((o) => !this.localState.filters[0].filters.map((o) => o.key).includes(o.id) || o.id === key); // remove options that are already selected except for supplied key
    }
    return [];
  }

  public getOperatorOptions(key: string): PropertyOption[] {
    const operators: PropertyOption[] = [];
    if(this.isMetric(key)) {
      const metricOperators = ['equals', 'notEquals', 'gte', 'lte', 'gt', 'lt'];
      metricOperators.forEach((o) => {
        operators.push({id: o, label: this.$t('condition.operator.'+o), description: ''});
      });
    }
    if(this.isDimension(key)) {
      const dimensionOperators = ['equals', 'notEquals', 'contains', 'notContains', 'match', 'notMatch'];
      dimensionOperators.forEach((o) => {
        operators.push({id: o, label: this.$t('condition.operator.'+o), description: ''});
      });
    }
    return operators;
  }

  public isMetric(key: string): boolean {
    const schema = DataSourceSchemaFactory.create(this.localState.filters[0].dataSource);
    return schema.metrics.map((m) => m.name).includes(key);
  }

  public isDimension(key: string): boolean {
    const schema = DataSourceSchemaFactory.create(this.localState.filters[0].dataSource);
    return schema.dimensions.map((d) => d.name).includes(key);
  }

  public addFilter(): void {
    this.openFilterManagement = true;
    if(this.localState.filters.length > 0) {
      const key = this.getKeyOptions('')[0].id;
      // const value = this.isMetric(key) ? 0 : '';
      const value = '';
      this.localState.filters[0].filters.push({key: key, operator: 'equals', value: value});
    }
  }

  public removeFilter(key: string): void {
    if(this.localState.filters.length > 0) {
      this.localState.filters[0].filters = this.localState.filters[0].filters.filter((f) => f.key !== key)
    }
  }

  public clearFilters(): void {
    if(this.localState.filters.length > 0) {
      this.localState.filters[0].filters = [];
      this.change();
    }
  }

  public closeFilterManagement(): void {
    this.openFilterManagement = false;
    this.localState.filters = JSON.parse(JSON.stringify(this.value));
  }

  @Emit("input")
  public change(): {filters: ReportFilter[]} {
    this.openFilterManagement = false;
    return {filters: this.localState.filters};
    // return {filters: [...this.localState.filters]};
  }

  @Watch('value')
  onValueChanged(value: ReportFilter[]) {
    // this.localState.filters = value.map((v) => v);
    // this.localState.filters = [...value];
    this.localState.filters = JSON.parse(JSON.stringify(value));
  }

  async created() {
    if(this.value) {
      // this.localState.filters = this.value.map((v) => v);
      // this.localState.filters = [...this.value];
      this.localState.filters = JSON.parse(JSON.stringify(this.value));
    }
  }
}

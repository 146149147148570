import {PropertyLimit} from '@/modules/report/limit/PropertyLimit';
import {ReportChart} from '@/models/report';

export class ScatterChartLimit implements PropertyLimit {
    private chart: ReportChart;

    constructor(chart: ReportChart) {
        this.chart = chart;
    }

    public getDimensionMaxLimit(): number {
        return 1;
    }

    public getMetricMaxLimit(): number {
        return 2;
    }

    public getDimensionMinLimit(): number {
        return 1;
    }

    public getMetricMinLimit(): number {
        return 2;
    }
}


import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {v4 as uuidv4} from 'uuid';
import loading from '@/util/Loading';
import BaseLayout from '@/views/BaseLayout.vue';
import ViewHeader from '@/components/ViewHeader.vue';
import SegmentContext from '@/components/SegmentContext.vue';
import DateContext from '@/components/DateContext.vue';
import Panel from '@/components/Panel.vue';
import Icon from '@/components/Icon.vue';
import RowTemplateModal from '@/modules/report/components/RowTemplateModal.vue';
import ChartTypeModal from '@/modules/report/components/ChartTypeModal.vue';
import ReportPropertyEditor from '@/modules/report/components/ReportPropertyEditor.vue';
import ChartPropertyEditor from '@/modules/report/components/ChartPropertyEditor.vue';
import ChartPanel from '@/modules/report/components/ChartPanel.vue';
import {ReportChart, ReportChartData, ReportChartType, ReportColumn, ReportFilter, ReportRow} from '@/models/report';
import {ReportChartFactory} from '@/modules/report/ReportChartFactory';
import {AnalyticsApiQueryResponse, Filter} from '@/models/analytics-api';
import FilterContext from '@/components/FilterContext.vue';
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal.vue';

const namespace: string = 'report';


@Component({
  components: {
    FilterContext,
    BaseLayout,
    ViewHeader,
    SegmentContext,
    DateContext,
    Panel,
    Icon,
    RowTemplateModal,
    ChartTypeModal,
    DeleteConfirmationModal,
    ReportPropertyEditor,
    ChartPropertyEditor,
    ChartPanel,
  }
})
export default class ReportView extends Vue {
  @Getter('inEditMode') inEditMode!: boolean;
  @Getter('id', {namespace}) id?: string;
  @Getter('title', {namespace}) title?: string;
  @Getter('rows', {namespace}) rows?: ReportRow[];
  @Getter('filters', {namespace}) filters?: ReportFilter[];
  @Getter('chartData', {namespace}) chartData?: ReportChartData[];
  @Action('updateTitle', {namespace}) updateTitle: any;
  @Action('updateRows', {namespace}) updateRows: any;
  @Action('updateFilters', {namespace}) updateFilters: any;
  @Action('fetchReportData', {namespace}) fetchReportData: any;
  @Action('fetchChartData', {namespace}) fetchChartData: any;
  @Action('fetchReport', {namespace}) fetchReport: any;
  @Action('saveReport', {namespace}) saveReport: any;
  @Action('removeReport', {namespace}) removeReport: any;
  @Action('fetchPeriods') fetchPeriods: any;
  @Action('fetchDateContext') fetchDateContext: any;
  @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
  private selectedColumn: ReportColumn | null = null;
  private selectedChart: ReportChart | null = null;
  private localState: {title: string; rows: ReportRow[], filters: ReportFilter[]} = {title: '', rows: [], filters: []};

  get isLoading(): boolean {
    return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period', 'report-mutation']);
  }

  get isNew(): boolean {
    return this.$route.params.id === 'new';
  }

  get showReportPropertyEditor(): boolean {
    return this.inEditMode && this.selectedChart === null;
  }

  get showChartPropertyEditor(): boolean {
    return this.inEditMode && this.selectedChart !== null;
  }

  public onClickColumn(column: ReportColumn): void {
    if(!this.inEditMode) {
      return;
    }
    this.selectedColumn = column;
    if (column.charts.length === 0 && this.$refs && this.$refs.chartTypePicker && this.$refs.chartTypePicker.show) {
      this.$refs.chartTypePicker.show();
    }
    if(column.charts.length === 1) {
      this.selectChart(column.charts[0]);
    }
  }

  public onCloseChartType(): void {
    this.deactivateColumn();
  }

  public deactivateColumn(): void {
    this.selectedColumn = null;
  }

  public deactivateChart(): void {
    this.selectedChart = null;
  }

  public save(report: {title:string; filters: ReportFilter[]}): void {
    this.changeReport(report);
    this.saveReport();
  }

  public remove(): void {
    if(this.$refs.reportDeleteConfirmation) {
      this.$refs.reportDeleteConfirmation.show();
    }
  }

  public changeReport(report: {title:string; filters: ReportFilter[]}): void {
    this.localState.title = report.title;
    this.localState.filters = report.filters;
    this.updateTitle({title: this.localState.title}); // update central state
    this.updateFilters({filters: this.localState.filters}); // update central state
  }

  public changeFilter(filter: {filters: ReportFilter[]}): void {
    this.updateFilters(filter);
    this.fetchData();
  }

  public addRow(numberOfColumns: number): void {
    const rows = this.localState.rows?.map((row) => row) ?? [];
    const columns: ReportColumn[] = [];
    for (let i = 0; i < numberOfColumns; i++) {
      columns.push({id: uuidv4(), charts: []});
    }
    rows.push({id: uuidv4(), columns});
    this.localState.rows = rows;
    // this.updateRows({rows});
  }

  public removeEmptyRows(): void {
    this.localState.rows = this.localState.rows.filter((row) => row.columns.flatMap((column) => column.charts).length !== 0)
  }

  public addChart(type: ReportChartType): void {
    let chart: ReportChart|null = null;
    if(this.selectedColumn) {
      this.localState.rows?.forEach((row) => {
        row.columns.forEach((column) => {
          if(column.id === this.selectedColumn?.id && column.charts.length === 0) {
            chart = ReportChartFactory.create(type);
            column.charts.push(chart);
          }
        });
      });
      this.deactivateColumn();
      if(chart) this.selectChart(chart);
    }
    this.updateRows({rows: this.localState.rows});
  }

  public selectChart(chart: ReportChart): void {
    this.selectedChart = chart;
  }

  public changeChart(chart: ReportChart|null): void {
    this.localState.rows = this.localState.rows.map((row) => {
      row.columns = row.columns.map((col) => {
        col.charts = col.charts.map((ch) => {
          if(ch.id === chart?.id) {
            return chart;
          }
          return ch;
        })
        return col;
      })
      return row;
    });
    this.updateRows({rows: this.localState.rows}); // update central state
    this.fetchChartData({chart});
  }

  public closeChart(chart: ReportChart|null): void {
    this.deactivateChart();
  }

  public removeChart(chart: ReportChart|null): void {
    this.localState.rows = this.localState.rows.map((r) => {
      r.columns = r.columns.map((c) => {
        c.charts = c.charts.filter((ch) => ch.id !== chart?.id)
        return c;
      })
      return r;
    });
    this.deactivateChart();
    this.removeEmptyRows();
  }


  public getChartData(chartId: string): AnalyticsApiQueryResponse|null {
    if (this.chartData) {
      const s = this.chartData.find((cd) => cd.chartId === chartId);
      if (s) {
        return s.data;
      }
    }
    return null;
  }

  public getRowStyleClass(row: ReportRow): string {
    let styleClass = 'view-content-row';
    if (row.columns.length > 1) {
      styleClass += '--columns-' + row.columns.length;
    }
    if (this.inEditMode) {
      styleClass += ' view-content-row__edit';
    }
    return styleClass;
  }

  public getColumnStyleClass(column: ReportColumn): string {
    let styleClass = 'view-content-column';
    if (this.inEditMode) {
      styleClass += ' view-content-column__edit';
    }
    return styleClass;
  }

  public getChartStyleClass(chart: ReportChart): string {
    let styleClass = 'view-content-chart';
    if (this.inEditMode) {
      styleClass += ' view-content-chart__edit';
    }
    if (this.selectedChart?.id === chart.id) {
      styleClass += ' view-content-chart__selected';
    }
    return styleClass;
  }

  @Watch('title')
  onTitleChanged(title: string) {
    this.localState.title = title;
  }

  @Watch('rows')
  onRowsChanged(rows: ReportRow[]) {
    this.localState.rows = rows;
  }

  @Watch('filters')
  onFiltersChanged(filters: ReportFilter[]) {
    this.localState.filters = filters;
  }

  @Watch('inEditMode')
  onEditModeChange(inEditMode: boolean) {
    if(!inEditMode) {
      this.selectedColumn = null;
      this.selectedChart = null;
    }
  }

  public fetchData(): void {
    this.fetchReportData();
  }

  // async created() {
  //   if(this.title) this.localState.title = this.title;
  //   if(this.rows) this.localState.rows = this.rows;
  // }

  async mounted() {
    if(this.title) this.localState.title = this.title;
    if(this.rows) this.localState.rows = this.rows;
    if(this.filters) this.localState.filters = this.filters;

    await this.fetchSegmentSummaries();
    await this.fetchPeriods();
    this.fetchDateContext();
    if(!this.isNew) {
      await this.fetchReport({id: this.$route.params.id});
      // await this.fetchData();
    }
  }


}

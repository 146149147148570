import i18n from '@/i18n';
import {DataSourceSchema, PropertyOption, TE_DATASOURCE_NAME} from '@/models/report';


export const dataSourceOptions: PropertyOption[] = [
    {
        id: TE_DATASOURCE_NAME.orderLines,
        label: i18n.t('report.dataSource.type.OrderLines.name'),
        description: i18n.t('report.dataSource.type.OrderLines.description'),
    },
    {
        id: TE_DATASOURCE_NAME.payments,
        label: i18n.t('report.dataSource.type.Payments.name'),
        description: i18n.t('report.dataSource.type.Payments.description'),
    },
];


export const transactionLinesConfig: DataSourceSchema = {
    dimensions: [
        {name: 'te.salesChannel', field: 'sales_channel', dataType: 'string'},
        {name: 'te.salesChannelId', field: 'sales_channel_id', dataType: 'string'},
        {name: 'te.register', field: 'register', dataType: 'string'},
        {name: 'te.registerId', field: 'register_id', dataType: 'string'},
        {name: 'te.priceType', field: 'price_type', dataType: 'string'},

        {name: 'te.transactionId', field: 'transaction_id', dataType: 'string'},
        {name: 'te.transactionStatus', field: 'transaction_status', dataType: 'string'},
        {name: 'te.transactionLineStatus', field: 'transaction_line_status', dataType: 'string'},

        {name: 'te.currencyCode', field: 'currency_code', dataType: 'string'},

        {name: 'te.transactionDate', field: 'transaction_date', dataType: 'string', dateUnit: 'dateTime'},
        {name: 'te.transactionYear', field: 'transaction_year', dataType: 'string', dateUnit: 'year'},
        {name: 'te.transactionMonth', field: 'transaction_month', dataType: 'string', dateUnit: 'month'},
        {name: 'te.transactionDay', field: 'transaction_day', dataType: 'string', dateUnit: 'day'},
        {name: 'te.transactionHour', field: 'transaction_hour', dataType: 'string', dateUnit: 'hour'},
        {name: 'te.transactionMinute', field: 'transaction_minute', dataType: 'string', dateUnit: 'minute'},
        {
            name: 'te.transactionDayOfWeek',
            field: 'transaction_day_of_week',
            dataType: 'string',
            dateUnit: 'dayOfWeek',
        },
        {name: 'te.transactionWeek', field: 'transaction_week', dataType: 'string', dateUnit: 'week'},
        {
            name: 'te.transactionDateHour',
            field: 'transaction_date_hour',
            dataType: 'string',
            dateUnit: 'dateHour',
        },
        {
            name: 'te.transactionDateHourMinute',
            field: 'transaction_date_hour_minute',
            dataType: 'string',
            dateUnit: 'dateHourMinute',
        },

        {name: 'te.eventStartDate', field: 'event_start_date', dataType: 'string', dateUnit: 'dateTime'},
        {name: 'te.eventStartYear', field: 'event_start_year', dataType: 'string', dateUnit: 'year'},
        {name: 'te.eventStartMonth', field: 'event_start_month', dataType: 'string', dateUnit: 'month'},
        {name: 'te.eventStartDay', field: 'event_start_day', dataType: 'string', dateUnit: 'day'},
        {name: 'te.eventStartHour', field: 'event_start_hour', dataType: 'string', dateUnit: 'hour'},
        {name: 'te.eventStartMinute', field: 'event_start_minute', dataType: 'string', dateUnit: 'minute'},
        {
            name: 'te.eventStartDayOfWeek',
            field: 'event_start_day_of_week',
            dataType: 'string',
            dateUnit: 'dayOfWeek',
        },
        {name: 'te.eventStartWeek', field: 'event_start_week', dataType: 'string', dateUnit: 'week'},
        {
            name: 'te.eventStartDateHour',
            field: 'event_start_date_hour',
            dataType: 'string',
            dateUnit: 'dateHour',
        },
        {
            name: 'te.eventStartDateHourMinute',
            field: 'event_start_date_hour_minute',
            dataType: 'string',
            dateUnit: 'dateHourMinute',
        },

        {name: 'te.eventEndDate', field: 'event_end_date_time', dataType: 'string'},

        {name: 'te.eventDurationBracket', field: 'event_duration_bracket', dataType: 'string'},
        {name: 'te.eventCategory', field: 'event_category', dataType: 'string'},
        {name: 'te.eventOutline', field: 'event_outline', dataType: 'string'},
        {name: 'te.eventTitle', field: 'event_title', dataType: 'string'},
        {name: 'te.eventLocation', field: 'event_location', dataType: 'string'},
        {name: 'te.eventId', field: 'event_id', dataType: 'string'},

        {name: 'te.customer', field: 'customer', dataType: 'string'},
        {name: 'te.customerType', field: 'customer_type', dataType: 'string'},
        {name: 'te.customerGender', field: 'customer_gender', dataType: 'string'},
        {name: 'te.customerAgeBracket', field: 'customer_age_bracket', dataType: 'string'},

        // {name: 'te.dateRange', field: 'date_range', dataType: 'string'},
        // {name: 'te.period', field: 'period_id', dataType: 'string'},
        // {name: 'te.segment', field: 'segment_id', dataType: 'string'},
    ],
    metrics: [
        {name: 'te.sold', field: 'sold', dataType: 'integer', aggregateFunction: 'sum'},
        {name: 'te.reserved', field: 'reserved', dataType: 'integer', aggregateFunction: 'sum'},
        {name: 'te.inCart', field: 'in_cart', dataType: 'integer', aggregateFunction: 'sum'},
        // {name: 'te.abandoned', field: 'abandoned', dataType: 'integer', aggregateFunction: 'sum'},
        {name: 'te.access', field: 'sold', dataType: 'integer', aggregateFunction: 'sum'},
        {name: 'te.revenue', field: 'revenue', dataType: 'currency', aggregateFunction: 'sum'},
        {name: 'te.revenueExTax', field: 'revenue_ex_tax', dataType: 'currency', aggregateFunction: 'sum'},
        {name: 'te.tax', field: 'tax', dataType: 'currency', aggregateFunction: 'sum'},
        {name: 'te.groupSize', field: 'group_size', dataType: 'float', aggregateFunction: 'avg'},

        {
            name: 'te.eventOccupancyRate',
            field: 'event_occupancy_rate',
            dataType: 'float',
            aggregateFunction: 'max',
        },
        {name: 'te.eventSold', field: 'event_sold', dataType: 'integer', aggregateFunction: 'max'},
        {name: 'te.eventReserved', field: 'event_reserved', dataType: 'integer', aggregateFunction: 'max'},
        {name: 'te.eventCapacity', field: 'event_capacity', dataType: 'integer', aggregateFunction: 'max'},
        {name: 'te.eventRevenue', field: 'event_revenue', dataType: 'currency', aggregateFunction: 'max'},
        {
            name: 'te.eventRevenueExTax',
            field: 'event_revenue_ex_tax',
            dataType: 'currency',
            aggregateFunction: 'max',
        },
        {name: 'te.eventTax', field: 'event_tax', dataType: 'currency', aggregateFunction: 'max'},

        {
            name: 'te.eventDurationSeconds',
            field: 'event_duration_in_seconds',
            dataType: 'integer',
            aggregateFunction: 'avg',
        },
        {
            name: 'te.uniqueCustomers',
            field: 'customer_id',
            dataType: 'integer',
            aggregateFunction: 'countDistinct',
        },
        {
            name: 'te.salesVelocityDays',
            field: 'sales_velocity_days',
            dataType: 'float',
            aggregateFunction: 'avg',
        },

        {
            name: 'te.transactionValue',
            field: 'transaction_revenue',
            dataType: 'float',
            aggregateFunction: 'avg',
        },

        {name: 'te.visitedEvents', field: 'event_id', dataType: 'float', aggregateFunction: 'countDistinct'},
        // {name: 'te.visitedPeriods', field: 'period_id', dataType: 'float', aggregateFunction: 'countDistinct'},
    ],
};


export const paymentsConfig: DataSourceSchema = {
    dimensions: [
        // {name: 'te.dateRange', field: 'date_range', dataType: 'string'},
        // {name: 'te.period', field: 'period_id', dataType: 'string'},
        // {name: 'te.segment', field: 'segment_id', dataType: 'string'},

        {name: 'te.transactionId', field: 'transaction_id', dataType: 'string'},
        {name: 'te.transactionStatus', field: 'transaction_status', dataType: 'string'},

        {name: 'te.currencyCode', field: 'currency_code', dataType: 'string'},

        {name: 'te.paymentId', field: 'payment_id', dataType: 'string'},
        {name: 'te.paymentStatus', field: 'payment_status', dataType: 'string'},
        {name: 'te.paymentMethod', field: 'payment_method', dataType: 'string'},
        {name: 'te.paymentServiceProvider', field: 'psp', dataType: 'string'},

        {name: 'te.transactionDate', field: 'transaction_date', dataType: 'string', dateUnit: 'dateTime'},
        {name: 'te.transactionYear', field: 'transaction_year', dataType: 'string', dateUnit: 'year'},
        {name: 'te.transactionMonth', field: 'transaction_month', dataType: 'string', dateUnit: 'month'},
        {name: 'te.transactionDay', field: 'transaction_day', dataType: 'string', dateUnit: 'day'},
        {name: 'te.transactionHour', field: 'transaction_hour', dataType: 'string', dateUnit: 'hour'},
        {name: 'te.transactionMinute', field: 'transaction_minute', dataType: 'string', dateUnit: 'minute'},
        {
            name: 'te.transactionDayOfWeek',
            field: 'transaction_day_of_week',
            dataType: 'string',
            dateUnit: 'dayOfWeek',
        },
        {name: 'te.transactionWeek', field: 'transaction_week', dataType: 'string', dateUnit: 'week'},
        {
            name: 'te.transactionDateHour',
            field: 'transaction_date_hour',
            dataType: 'string',
            dateUnit: 'dateHour',
        },
        {
            name: 'te.transactionDateHourMinute',
            field: 'transaction_date_hour_minute',
            dataType: 'string',
            dateUnit: 'dateHourMinute',
        },

        {name: 'te.customer', field: 'customer', dataType: 'string'},
        {name: 'te.customerType', field: 'customer_type', dataType: 'string'},
        {name: 'te.customerGender', field: 'customer_gender', dataType: 'string'},
        {name: 'te.customerAgeBracket', field: 'customer_age_bracket', dataType: 'string'},
    ],
    metrics: [

        {name: 'te.paymentRevenue', field: 'revenue', dataType: 'currency', aggregateFunction: 'sum'},
        {name: 'te.payments', field: 'payment_count', dataType: 'currency', aggregateFunction: 'sum'},

    ],
};


//
// export const TransactionLinesMetricOptions: PropertyOption[] = [
//     {
//         id: 'sold',
//         label: i18n.t('metricsAndDimensions.name.te.sold'),
//         description: i18n.t('metricsAndDimensions.description.te.sold'),
//     },
//     {
//         id: 'reserved',
//         label: i18n.t('metricsAndDimensions.name.te.reserved'),
//         description: i18n.t('metricsAndDimensions.description.te.reserved'),
//     },
//     {
//         id: 'inCart',
//         label: i18n.t('metricsAndDimensions.name.te.inCart'),
//         description: i18n.t('metricsAndDimensions.description.te.inCart'),
//     },
//     {
//         id: 'abandoned',
//         label: i18n.t('metricsAndDimensions.name.te.abandoned'),
//         description: i18n.t('metricsAndDimensions.description.te.abandoned'),
//     },
//     {
//         id: 'access',
//         label: i18n.t('metricsAndDimensions.name.te.access'),
//         description: i18n.t('metricsAndDimensions.description.te.access'),
//     },
//     {
//         id: 'revenue',
//         label: i18n.t('metricsAndDimensions.name.te.revenue'),
//         description: i18n.t('metricsAndDimensions.description.te.revenue'),
//     },
//     {
//         id: 'revenueExTax',
//         label: i18n.t('metricsAndDimensions.name.te.revenueExTax'),
//         description: i18n.t('metricsAndDimensions.description.te.revenueExTax'),
//     },
//     {
//         id: 'tax',
//         label: i18n.t('metricsAndDimensions.name.te.tax'),
//         description: i18n.t('metricsAndDimensions.description.te.tax'),
//     },
//     {
//         id: 'groupSize',
//         label: i18n.t('metricsAndDimensions.name.te.groupSize'),
//         description: i18n.t('metricsAndDimensions.description.te.groupSize'),
//     },
//     {
//         id: 'eventOccupancyRate',
//         label: i18n.t('metricsAndDimensions.name.te.eventOccupancyRate'),
//         description: i18n.t('metricsAndDimensions.description.te.eventOccupancyRate'),
//     },
//     {
//         id: 'eventSold',
//         label: i18n.t('metricsAndDimensions.name.te.eventSold'),
//         description: i18n.t('metricsAndDimensions.description.te.eventSold'),
//     },
//     {
//         id: 'eventReserved',
//         label: i18n.t('metricsAndDimensions.name.te.eventReserved'),
//         description: i18n.t('metricsAndDimensions.description.te.eventReserved'),
//     },
//     {
//         id: 'eventCapacity',
//         label: i18n.t('metricsAndDimensions.name.te.eventCapacity'),
//         description: i18n.t('metricsAndDimensions.description.te.eventCapacity'),
//     },
//     {
//         id: 'eventRevenue',
//         label: i18n.t('metricsAndDimensions.name.te.eventRevenue'),
//         description: i18n.t('metricsAndDimensions.description.te.eventRevenue'),
//     },
//     {
//         id: 'eventRevenueExTax',
//         label: i18n.t('metricsAndDimensions.name.te.eventRevenueExTax'),
//         description: i18n.t('metricsAndDimensions.description.te.eventRevenueExTax'),
//     },
//     {
//         id: 'eventTax',
//         label: i18n.t('metricsAndDimensions.name.te.eventTax'),
//         description: i18n.t('metricsAndDimensions.description.te.eventTax'),
//     },
//     {
//         id: 'eventDurationSeconds',
//         label: i18n.t('metricsAndDimensions.name.te.eventDurationSeconds'),
//         description: i18n.t('metricsAndDimensions.description.te.eventDurationSeconds'),
//     },
//     {
//         id: 'uniqueCustomers',
//         label: i18n.t('metricsAndDimensions.name.te.uniqueCustomers'),
//         description: i18n.t('metricsAndDimensions.description.te.uniqueCustomers'),
//     },
//     {
//         id: 'salesVelocityDays',
//         label: i18n.t('metricsAndDimensions.name.te.salesVelocityDays'),
//         description: i18n.t('metricsAndDimensions.description.te.salesVelocityDays'),
//     },
//     {
//         id: 'transactionValue',
//         label: i18n.t('metricsAndDimensions.name.te.transactionValue'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionValue'),
//     },
//     {
//         id: 'visitedEvents',
//         label: i18n.t('metricsAndDimensions.name.te.visitedEvents'),
//         description: i18n.t('metricsAndDimensions.description.te.visitedEvents'),
//     },
//     {
//         id: 'visitedPeriods',
//         label: i18n.t('metricsAndDimensions.name.te.visitedPeriods'),
//         description: i18n.t('metricsAndDimensions.description.te.visitedPeriods'),
//     },
//
// ];
//
//
// export const TransactionLinesDescriptionOptions: PropertyOption[] = [
//     {
//         id: 'transactionId',
//         label: i18n.t('metricsAndDimensions.name.te.transactionId'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionId'),
//     },
//     {
//         id: 'transactionStatus',
//         label: i18n.t('metricsAndDimensions.name.te.transactionStatus'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionStatus'),
//     },
//     {
//         id: 'transactionLineStatus',
//         label: i18n.t('metricsAndDimensions.name.te.transactionLineStatus'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionLineStatus'),
//     },
//     {
//         id: 'salesChannelId',
//         label: i18n.t('metricsAndDimensions.name.te.salesChannelId'),
//         description: i18n.t('metricsAndDimensions.description.te.salesChannelId'),
//     },
//     {
//         id: 'salesChannel',
//         label: i18n.t('metricsAndDimensions.name.te.salesChannel'),
//         description: i18n.t('metricsAndDimensions.description.te.salesChannel'),
//     },
//     {
//         id: 'registerId',
//         label: i18n.t('metricsAndDimensions.name.te.registerId'),
//         description: i18n.t('metricsAndDimensions.description.te.registerId'),
//     },
//     {
//         id: 'register',
//         label: i18n.t('metricsAndDimensions.name.te.register'),
//         description: i18n.t('metricsAndDimensions.description.te.register'),
//     },
//     {
//         id: 'priceType',
//         label: i18n.t('metricsAndDimensions.name.te.priceType'),
//         description: i18n.t('metricsAndDimensions.description.te.priceType'),
//     },
//     {
//         id: 'currencyCode',
//         label: i18n.t('metricsAndDimensions.name.te.currencyCode'),
//         description: i18n.t('metricsAndDimensions.description.te.currencyCode'),
//     },
//     {
//         id: 'transactionDate',
//         label: i18n.t('metricsAndDimensions.name.te.transactionDate'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionDate'),
//     },
//     {
//         id: 'transactionYear',
//         label: i18n.t('metricsAndDimensions.name.te.transactionYear'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionYear'),
//     },
//     {
//         id: 'transactionMonth',
//         label: i18n.t('metricsAndDimensions.name.te.transactionMonth'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionMonth'),
//     },
//     {
//         id: 'transactionDay',
//         label: i18n.t('metricsAndDimensions.name.te.transactionDay'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionDay'),
//     },
//     {
//         id: 'transactionHour',
//         label: i18n.t('metricsAndDimensions.name.te.transactionHour'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionHour'),
//     },
//     {
//         id: 'transactionMinute',
//         label: i18n.t('metricsAndDimensions.name.te.transactionMinute'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionMinute'),
//     },
//     {
//         id: 'transactionDayOfWeek',
//         label: i18n.t('metricsAndDimensions.name.te.transactionDayOfWeek'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionDayOfWeek'),
//     },
//     {
//         id: 'transactionWeek',
//         label: i18n.t('metricsAndDimensions.name.te.transactionWeek'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionWeek'),
//     },
//     {
//         id: 'transactionDateHour',
//         label: i18n.t('metricsAndDimensions.name.te.transactionDateHour'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionDateHour'),
//     },
//     {
//         id: 'transactionDateHourMinute',
//         label: i18n.t('metricsAndDimensions.name.te.transactionDateHourMinute'),
//         description: i18n.t('metricsAndDimensions.description.te.transactionDateHourMinute'),
//     },
//     {
//         id: 'eventStartDate',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartDate'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartDate'),
//     },
//     {
//         id: 'eventStartYear',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartYear'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartYear'),
//     },
//     {
//         id: 'eventStartMonth',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartMonth'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartMonth'),
//     },
//     {
//         id: 'eventStartDay',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartDay'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartDay'),
//     },
//     {
//         id: 'eventStartHour',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartHour'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartHour'),
//     },
//     {
//         id: 'eventStartMinute',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartMinute'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartMinute'),
//     },
//     {
//         id: 'eventStartDayOfWeek',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartDayOfWeek'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartDayOfWeek'),
//     },
//     {
//         id: 'eventStartWeek',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartWeek'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartWeek'),
//     },
//     {
//         id: 'eventStartDateHour',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartDateHour'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartDateHour'),
//     },
//     {
//         id: 'eventStartDateHourMinute',
//         label: i18n.t('metricsAndDimensions.name.te.eventStartDateHourMinute'),
//         description: i18n.t('metricsAndDimensions.description.te.eventStartDateHourMinute'),
//     },
//     {
//         id: 'eventEndDate',
//         label: i18n.t('metricsAndDimensions.name.te.eventEndDate'),
//         description: i18n.t('metricsAndDimensions.description.te.eventEndDate'),
//     },
//     {
//         id: 'eventDurationBracket',
//         label: i18n.t('metricsAndDimensions.name.te.eventDurationBracket'),
//         description: i18n.t('metricsAndDimensions.description.te.eventDurationBracket'),
//     },
//     {
//         id: 'eventCategory',
//         label: i18n.t('metricsAndDimensions.name.te.eventCategory'),
//         description: i18n.t('metricsAndDimensions.description.te.eventCategory'),
//     },
//     {
//         id: 'eventOutline',
//         label: i18n.t('metricsAndDimensions.name.te.eventOutline'),
//         description: i18n.t('metricsAndDimensions.description.te.eventOutline'),
//     },
//     {
//         id: 'eventTitle',
//         label: i18n.t('metricsAndDimensions.name.te.eventTitle'),
//         description: i18n.t('metricsAndDimensions.description.te.eventTitle'),
//     },
//     {
//         id: 'eventLocation',
//         label: i18n.t('metricsAndDimensions.name.te.eventLocation'),
//         description: i18n.t('metricsAndDimensions.description.te.eventLocation'),
//     },
//     {
//         id: 'eventId',
//         label: i18n.t('metricsAndDimensions.name.te.eventId'),
//         description: i18n.t('metricsAndDimensions.description.te.eventId'),
//     },
//     {
//         id: 'customer',
//         label: i18n.t('metricsAndDimensions.name.te.customer'),
//         description: i18n.t('metricsAndDimensions.description.te.customer'),
//     },
//     {
//         id: 'customerType',
//         label: i18n.t('metricsAndDimensions.name.te.customerType'),
//         description: i18n.t('metricsAndDimensions.description.te.customerType'),
//     },
//     {
//         id: 'customerGender',
//         label: i18n.t('metricsAndDimensions.name.te.customerGender'),
//         description: i18n.t('metricsAndDimensions.description.te.customerGender'),
//     },
//     {
//         id: 'customerAgeBracket',
//         label: i18n.t('metricsAndDimensions.name.te.customerAgeBracket'),
//         description: i18n.t('metricsAndDimensions.description.te.customerAgeBracket'),
//     },
//
// ];
//
//
//
// export const PaymentsMetricOptions: PropertyOption[] = [
//     {
//         id: 'paymentRevenue',
//         label: i18n.t('metricsAndDimensions.name.te.paymentRevenue'),
//         description: i18n.t('metricsAndDimensions.description.te.paymentRevenue'),
//     },
//     {
//         id: 'payments',
//         label: i18n.t('metricsAndDimensions.name.te.payments'),
//         description: i18n.t('metricsAndDimensions.description.te.payments'),
//     },
// ];
//
// export const PaymentsDescriptionOptions: PropertyOption[] = [
//     {
//         id: '',
//         label: i18n.t('metricsAndDimensions.name.te.'),
//         description: i18n.t('metricsAndDimensions.description.te.'),
//     },
//
// ];

import {DataSource, DataSourceSchema, DataSourceType, TE_DATASOURCE_NAME} from '@/models/report';
import {transactionLinesConfig, paymentsConfig, dataSourceOptions} from '@/config/report';

export class DataSourceSchemaFactory {

    public static create(source: DataSource): DataSourceSchema {
        if (source.name === TE_DATASOURCE_NAME.orderLines && source.type === DataSourceType.TicketEngineAnalyticsApi) {
            return transactionLinesConfig;
        }
        if (source.name === TE_DATASOURCE_NAME.payments && source.type === DataSourceType.TicketEngineAnalyticsApi) {
            return paymentsConfig;
        }
        throw new Error(`No schema found for data source ${source.type} with name ${source.name}.`);
    }

}

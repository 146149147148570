
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Panel from '@/components/Panel.vue';
import DefinitionInfo from '@/components/DefinitionInfo.vue';
import IconButton from '@/components/IconButton.vue';
import LineChart from '@/components/Chart/LineChart.vue';
import BarChart from '@/components/Chart/BarChart.vue';
import ColumnChart from '@/components/Chart/ColumnChart.vue';
import PieChart from '@/components/Chart/PieChart.vue';
import TableChart from '@/components/Chart/TableChart.vue';
import AreaChart from '@/components/Chart/AreaChart.vue';
import ScatterChart from '@/components/Chart/ScatterChart.vue';
import BubbleChart from '@/components/Chart/BubbleChart.vue';
import Treemap from '@/components/Chart/Treemap.vue';
import {ReportChart, ReportChartType} from '@/models/report';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {CsvSeriesBuilder} from '@/util/CsvSeriesBuilder';
import exportService from '@/services/ExportService';
import {Getter} from 'vuex-class';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from '@/models/date-context';
import {SegmentSummery} from '@/models/segment';

@Component({
  components: {
    IconButton,
    Panel,
    DefinitionInfo,
    LineChart,
    BarChart,
    ColumnChart,
    PieChart,
    TableChart,
    AreaChart,
    ScatterChart,
    BubbleChart,
    Treemap,
  }
})
export default class ChartPropertyEditor extends Vue {
  @Prop({required:true}) private value?: ReportChart;
  @Prop({required:true}) private data?: AnalyticsApiQueryResponse;
  @Getter('periods') periods!: Period[];
  @Getter('selectedSegments') selectedSegments!: SegmentSummery[];
  @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;

  get chartTitle(): string {
    if(this.value) {
      return this.value.title;
    }
    return '';
  }

  get chartDimensions(): string[] {
    if(this.value?.useSegments && this.value?.type !== ReportChartType.table) {
      const dimensions = [...this.value?.dimensions];
      dimensions.push('te.segment');
      return dimensions;
    }
    return this.value?.dimensions ?? [];
  }

  get chartMetrics(): string[] {
    return this.value?.metrics ?? [];
  }

  get chartDateContext(): DateContextPeriod|DateContextEvent|DateContextTransaction|undefined {
    if(this.value?.type === ReportChartType.table) {
      return this.dateContext;
    }
    return this.value?.useDateContext ? this.dateContext : undefined;
  }

  get chartSelectedSegments(): SegmentSummery[] {
    return this.value?.useSegments ? this.selectedSegments : [];
    // return this.selectedSegments;
  }

  get chartDefinitions(): {name: string, description: string}[] {
    const definitions: {name: string, description: string}[] = [];
    this.value?.dimensions.forEach((dimension) => {
      definitions.push({name: String(this.$t('metricsAndDimensions.name.'+dimension)), description: String(this.$t('metricsAndDimensions.description.'+dimension))});
    });
    this.value?.metrics.forEach((metric) => {
      definitions.push({name: String(this.$t('metricsAndDimensions.name.'+metric)), description: String(this.$t('metricsAndDimensions.description.'+metric))});
    });
    return definitions;
  }

  public exportData(): void {
    if(this.value && this.data) {
      const builder = new CsvSeriesBuilder(this.data, this.chartSelectedSegments, this.chartDimensions, this.chartMetrics, this.periods, this.chartDateContext);
      exportService.csv(builder.build(), 'export.csv');
    }
  }


}

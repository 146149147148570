
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {PropertyOption, ReportChart} from '@/models/report';

@Component({
  components: {

  }
})
export default class PropertyPanelSelect extends Vue {
  @Prop({required: true}) private value!: string;
  @Prop({required: true}) private options!: PropertyOption[];
  private localState: {selected: string} = {selected: ''};

  // public show(): void {
  //   this.$modal.show(this.name);
  // }

  // public hide(): void {
  //   this.$modal.hide(this.name);
  // }

  @Emit("input")
  public onSelect(): {newValue: string, oldValue: string} {
    return {newValue: this.localState.selected, oldValue: this.value};
  }

  @Watch('value')
  onValueChanged(value: string) {
    this.localState.selected = value;
  }

  async created() {
    if(this.value) {
      this.localState.selected = this.value;
    }
  }
}

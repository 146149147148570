
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {DataSourceType, PropertyOption, ReportFilter, TE_DATASOURCE_NAME} from '@/models/report';
import Button from '@/components/Button.vue';
import PropertyPanelSelect from '@/modules/report/components/PropertyPanelSelect.vue';
import {dataSourceOptions} from '@/config/report';
import {Getter} from 'vuex-class';

const namespace: string = 'report';

@Component({
  components: {
    'te-button': Button,
    PropertyPanelSelect,
  }
})
export default class ReportPropertyEditor extends Vue {
  @Prop({required:true}) private title?: string;
  @Prop() private filters?: ReportFilter[];
  @Getter('isNew', {namespace}) isNew?: boolean;
  private localState: {title: string, filters: ReportFilter[]} = {title: '', filters: []};


  get filterValue(): string {
    if(this.localState.filters.length > 0) {
      return this.localState.filters[0].dataSource.name;
    }
    return '-';
  }

  get filterOptions(): PropertyOption[] {
    return [
      {id: '-', label: this.$t('report.filter.noFilter'), description: ''},
      {id: TE_DATASOURCE_NAME.orderLines, label: this.$t('report.dataSource.type.OrderLines.name'), description: ''},
      {id: TE_DATASOURCE_NAME.payments, label: this.$t('report.dataSource.type.Payments.name'), description: ''},
    ]
  }

  public changedFilter(change: {newValue: string, oldValue: string}): void {
    if(change.newValue !== '-' && this.localState.filters.length === 0) {
      this.localState.filters.push({filters: [], dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: change.newValue}});
    }
    if(change.newValue !== '-' && this.localState.filters.length > 0) {
      this.localState.filters[0].dataSource.name = change.newValue;
    }
    if(change.newValue === '-') {
      this.localState.filters = [];
    }
  }


  @Emit("change")
  public change(): {title: string; filters: ReportFilter[]} {
    return {title: this.localState.title, filters: this.localState.filters};
  }

  @Emit("save")
  public save(): {title: string; filters: ReportFilter[]} {
    return {title: this.localState.title, filters: this.localState.filters};
  }

  @Emit("remove")
  public remove(): void {
    return;
  }

  @Watch('title')
  onValueChanged(title: string) {
    this.localState.title = title;
  }

  @Watch('filters')
  onFiltersChanged(filters: ReportFilter[]) {
    this.localState.filters = filters.map((v) => v);
  }

  async created() {
    if(this.title) {
      this.localState.title = this.title;
      this.localState.filters = this.filters ?? [];
    }
  }
}

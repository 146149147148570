
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import DefaultModal from '@/components/DefaultModal.vue';
import {ReportChartType} from '@/models/report';

// draw icons using https://editsvgcode.com/
@Component({
  components: {
    DefaultModal
  }
})
export default class RowTemplateModal extends Vue {
  @Prop({default: 'chart-type-picker', type: String}) private name!: string;

  public show(): void {
    this.$modal.show(this.name);
  }

  public hide(): void {
    this.$modal.hide(this.name);
  }

  @Emit("close")
  public close(): string {
    this.hide();
    return this.name;
  }

  @Emit("select")
  public onSelect(type: string): ReportChartType {
    this.hide();
    return (<any>ReportChartType)[type];
  }
}

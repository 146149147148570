import {DataSourceType, ReportChart, ReportChartType} from '@/models/report';
import {v4 as uuidv4} from 'uuid';
import {dataSourceOptions} from '@/config/report';


export class ReportChartFactory {
    public static create(chartType: ReportChartType): ReportChart {
        if ([ReportChartType.line, ReportChartType.area].includes(chartType)) {
            return {
                id: uuidv4(),
                type: chartType,
                dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: dataSourceOptions[0].id},
                dimensions: ['te.transactionDate'],
                metrics: ['te.sold'],
                title: '',
                useSegments: true,
                useDateContext: true,
                useReportFilters: true,
                includeEmptyRows: true,
            };
        }
        if ([ReportChartType.scatter].includes(chartType)) {
            return {
                id: uuidv4(),
                type: chartType,
                dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: dataSourceOptions[0].id},
                dimensions: [],
                metrics: ['te.sold', 'te.revenue'],
                title: '',
                useSegments: true,
                useDateContext: true,
                useReportFilters: true,
                includeEmptyRows: true,
            };
        }
        if ([ReportChartType.bubble].includes(chartType)) {
            return {
                id: uuidv4(),
                type: chartType,
                dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: dataSourceOptions[0].id},
                dimensions: ['te.salesChannel'],
                metrics: ['te.salesVelocityDays', 'te.revenue', 'te.groupSize'],
                title: '',
                useSegments: true,
                useDateContext: false,
                useReportFilters: true,
                includeEmptyRows: true,
            };
        }

        return {
            id: uuidv4(),
            type: chartType,
            dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: dataSourceOptions[0].id},
            dimensions: ['te.priceType'],
            metrics: ['te.sold'],
            title: '',
            useSegments: true,
            useDateContext: true,
            useReportFilters: true,
            includeEmptyRows: true,
        };
    }
}


import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import DefaultModal from '@/components/DefaultModal.vue';
import Button from '@/components/Button.vue';

@Component({
  components: {
    DefaultModal,
    'te-button': Button,
  }
})
export default class DeleteConfirmationModal extends Vue {
  @Prop({default: 'delete-confirmation', type: String}) private name!: string;
  @Prop({required: true}) private title!: string;
  @Prop({required: true}) private description!: string;

  public show(): void {
    this.$modal.show(this.name);
  }

  public hide(): void {
    this.$modal.hide(this.name);
  }

  @Emit("close")
  public close(): void {
    this.hide();
    return;
  }

  @Emit("delete")
  public remove(): void {
    this.hide();
    return;
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';

const namespace: string = 'report';

@Component({
  components: {
    Icon,
  }
})
export default class DefinitionInfo extends Vue {
  @Prop() private definitions!: {name: string, description: string}[];


}

import {PropertyLimit} from '@/modules/report/limit/PropertyLimit';
import {ReportChart, ReportChartType} from '@/models/report';
import {LineChartLimit} from '@/modules/report/limit/LineChartLimit';
import {ColumnChartLimit} from '@/modules/report/limit/ColumnChartLimit';
import {PieChartLimit} from '@/modules/report/limit/PieChartLimit';
import {TableChartLimit} from '@/modules/report/limit/TableChartLimit';
import {BarChartLimit} from '@/modules/report/limit/BarChartLimit';
import {TreemapLimit} from '@/modules/report/limit/TreemapLimit';
import {ScatterChartLimit} from '@/modules/report/limit/ScatterChartLimit';
import {AreaChartLimit} from '@/modules/report/limit/AreaChartLimit';
import {BubbleChartLimit} from '@/modules/report/limit/BubbleChartLimit';

export class PropertyLimitFactory {
    public static create(chart: ReportChart): PropertyLimit {
        if (chart.type === ReportChartType.line) {
            return new LineChartLimit(chart);
        }
        if (chart.type === ReportChartType.bar) {
            return new BarChartLimit(chart);
        }
        if (chart.type === ReportChartType.column) {
            return new ColumnChartLimit(chart);
        }
        if (chart.type === ReportChartType.pie) {
            return new PieChartLimit(chart);
        }
        if (chart.type === ReportChartType.area) {
            return new AreaChartLimit(chart);
        }
        if (chart.type === ReportChartType.scatter) {
            return new ScatterChartLimit(chart);
        }
        if (chart.type === ReportChartType.bubble) {
            return new BubbleChartLimit(chart);
        }
        if (chart.type === ReportChartType.treemap) {
            return new TreemapLimit(chart);
        }
        if (chart.type === ReportChartType.table) {
            return new TableChartLimit(chart);
        }
        throw new Error(`Invalid ReportChart of type ${chart.type}.`);
    }
}

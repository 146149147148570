
import {Component, Vue, Prop, Emit} from 'vue-property-decorator';
    import Icon from '@/components/Icon.vue';

    @Component({
        components: {
            Icon,
        }
    })
    export default class DefaultModal extends Vue {
        @Prop({default: '600px', type: String}) private width!: number;
        @Prop({required: true, type: String}) private title!: string;
        @Prop({default: 'default', type: String}) private name!: string;
        @Prop({default: false, type: Boolean}) private showCloseButton!: boolean;
        @Prop({default: false, type: Boolean}) private hasLightBackground!: boolean;

        get classes(): string {
            if (this.hasLightBackground) {
                return "v--modal v--modal--has-light-background";
            }
            return "v--modal";
        }

        public show(): void {
            this.$modal.show(this.name);
        }

        public hide(): void {
console.log('hide default modal');
          this.$modal.hide(this.name);
        }

        @Emit("close")
        public close(): string {
console.log('close default modal');
          return this.name;
        }



    }
